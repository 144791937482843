import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import ingressService from "../../../services/ingressService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as styles from "./styles";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import userService from "../../../services/userService";

const initialState = {
  comprobante: "",
  contacto: "",
  deposito: "",
  telefono: "",
  email: "",
  observaciones: "",
};

const ModalIngreso = ({
  totalUnidadesIngresadas,
  productosSeleccionados,
  handleRemoveProduct,
  cantidadIngresadaErrors,
  canConfirm,
  setAlertObj,
  setOpen,
  handleCloseModal,
  setProductosSeleccionados,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [depositos, setdepositos] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Limpiar el error cuando el usuario vuelve a escribir
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  useEffect(() => {
    fetchDepositos();
  }, []);

  const fetchDepositos = async () => {
    const result = await userService.getDeposits();
    setdepositos(result.data.Depositos);
    console.log("resultado de tipos de egreso", result);
  };

  /*  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Ingresa un correo electrónico válido",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  }; */

  const handleConfirm = async () => {
    // Validar campos requeridos
    const requiredFields = ["comprobante", "contacto", "deposito", "telefono"];
    const newFormErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      console.log("formData[field]", formData[field]);
      if (!formData[field].trim()) {
        newFormErrors[field] = "Campo requerido";
        hasErrors = true;
      }
    });

    if (hasErrors) {
      console.log("hasErrors", hasErrors);
      console.log("newFormErrors", newFormErrors);
      setFormErrors(newFormErrors);
      return;
    }

    // Validar cantidad ingresada
    const hasCantidadIngresadaErrors = Object.values(
      cantidadIngresadaErrors
    ).some((error) => error !== "");

    if (hasCantidadIngresadaErrors) {
      console.log("hasCantidadIngresadaErrors", hasCantidadIngresadaErrors);
      // Mostrar mensaje de error general
      setFormErrors({ cantidadIngresada: "Campos requeridos" });
      return;
    }

    setLoading(true);

    if (canConfirm()) {
      const requestBody = {
        ...formData,
        detallesIngreso: productosSeleccionados.map((producto) => ({
          sku: producto.sku,
          cantidadIngresada: producto.cantidadIngresada,
        })),
      };

      try {
        let nuevoIngreso = await ingressService.postCreateIngress(requestBody);
        if (nuevoIngreso?.data?.Registrado) {
          setAlertObj({
            severity: "success",
            message: nuevoIngreso?.data?.Registrado,
          });
          setOpen(true);
        } else if (nuevoIngreso?.data?.Error) {
          setAlertObj({
            severity: "error",
            message: nuevoIngreso?.data.Error,
          });
          setOpen(true);
        }
        generatePDF(nuevoIngreso.data.Id);
        handleCloseModal();
      } catch {
        setAlertObj({
          severity: "error",
          message: "Ocurrió un error en la creación",
        });
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const generatePDF = (id) => {
    const doc = new jsPDF();
    const currentDate = new Date().toLocaleDateString();

    doc.text("Detalle de Ingreso", 20, 10);
    doc.text(`Fecha de Creación: ${currentDate}`, 20, 20);
    doc.text(`ID Orden: ${id}`, 20, 30);
    doc.text(`Comprobante: ${formData.comprobante}`, 20, 40);
    doc.text(`Contacto: ${formData.contacto}`, 20, 50);
    doc.text(`Depósito: ${formData.deposito}`, 20, 60);
    doc.text(`Teléfono: ${formData.telefono}`, 20, 70);
    doc.text(`Email: ${formData.email}`, 20, 80);
    doc.text(`Observaciones: ${formData.observaciones}`, 20, 90);
    doc.text(
      `Total de unidades a ingresar: ${totalUnidadesIngresadas}`,
      20,
      100
    );
    doc.text(`Total Sku's: ${productosSeleccionados.length}`, 20, 110);

    // Generar tabla con la información de los productos
    const data = productosSeleccionados.map((producto) => [
      producto.sku,
      producto.nombre,
      producto.cantidadIngresada.toString(), // Asegurarse de que la cantidad sea un string
    ]);

    // Ajustar el formato de la tabla
    autoTable(doc, {
      startY: 120,
      head: [["SKU", "Descripción", "Cantidad Ingresada"]],
      body: data,
    });

    // Guardar el PDF o abrirlo en una nueva ventana
    // Puedes ajustar el nombre del archivo según tus necesidades
    doc.save(`Detalle_Ingreso_${id}.pdf`);
    setProductosSeleccionados([]);
    setFormData(initialState);
    handleCloseModal();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Comprobante asociado"
            name="comprobante"
            value={formData.comprobante}
            onChange={handleChange}
            error={!!formErrors.comprobante}
            helperText={formErrors.comprobante}
          />
        </Grid>
        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Contacto"
            name="contacto"
            value={formData.contacto}
            onChange={handleChange}
            error={!!formErrors.contacto}
            helperText={formErrors.contacto}
          />
        </Grid>
        <Grid item xs={6} component={styles.InputContainer}>
          <Grid item xs={12}>
            <FormControl sx={{ width: { xs: "80%", md: "40%" } }}>
              <InputLabel id="select-label">
                {!!formErrors.deposito
                  ? formErrors.deposito
                  : "Seleccione una opcion"}
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={formData.deposito}
                label="Seleccione una opcion"
                name="deposito"
                onChange={handleChange}
                error={!!formErrors.deposito}
                helperText={formErrors.deposito}
              >
                <MenuItem value="">Seleccione una opcion</MenuItem>
                {depositos.map((element) => {
                  return (
                    <MenuItem value={element.nombre}>{element.nombre}</MenuItem>
                  );
                })}
                {/* <MenuItem value="cliente">Cliente Retira</MenuItem>
            <MenuItem value="flete">Flete Propio</MenuItem>
            <MenuItem value="redespacho">Redespacho</MenuItem>
            <MenuItem value="transporte">Transporte retira</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Telefono"
            name="telefono"
            type="text"
            value={formData.telefono}
            onChange={handleChange}
            error={!!formErrors.telefono}
            helperText={formErrors.telefono}
            inputProps={{
              pattern: "[0-9]*", // Solo permite números
              title: "Ingrese solo números",
            }}
          />
        </Grid>

        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Observaciones"
            name="observaciones"
            value={formData.observaciones}
            onChange={handleChange}
            error={!!formErrors.observaciones}
            helperText={formErrors.observaciones}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <Typography>
            Total de unidades a ingresar: <b>{totalUnidadesIngresadas}</b>
          </Typography>
          <br></br>
          <Typography>
            Total Sku's: <b>{productosSeleccionados.length}</b>
          </Typography>
        </Grid>
        <ListadoDeProductos
          productos={productosSeleccionados}
          handleRemoveProduct={handleRemoveProduct}
          withActions={true}
        />
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={handleCloseModal}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {loading ? "Cargando..." : "Confirmar ingreso"}
        </Button>
      </Grid>
    </>
  );
};

export default ModalIngreso;
