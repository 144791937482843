import client from "../client/client";
import { TOKEN, ROL, USER } from "../utils/constants";

const singIn = async (data) => {
  let resp = await client.post("/user-api/login", data);
  if (resp.status === 200 && resp.data.token) {
    sessionStorage.setItem(TOKEN, resp.data.token);
    sessionStorage.setItem(ROL, resp?.data?.Role);
    sessionStorage.setItem(USER, resp?.data?.User);
  }

  return resp;
};

const singOut = async () => {
  sessionStorage.clear();
};

let AuthService = { singIn, singOut };
export default AuthService;
