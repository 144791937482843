import client from "../client/client";

const postCreateIngress = async (data) => {
  try {
    const resp = await client.post("productos/ingresos/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};

const getAllIngress = async (paginacion) => {
  try {
    const resp = await client.get("productos/ingresos/", {
      params: paginacion,
    });
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos:", error);
    throw error;
  }
};

const getIngressById = async (id) => {
  try {
    const resp = await client.get(`productos/detallesIngreso/?busqueda=${id}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos:", error);
    throw error;
  }
};

const getAllIngressPending = async () => {
  try {
    const resp = await client.get("productos/ingresosPendientes/");
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos pendientes:", error);
    throw error;
  }
};

const getInventory = async (filter) => {
  try {
    const queryString = filter ? `?description=${filter}` : ""; // Construye el queryString
    let resp = await client.get(`product-api/search${queryString}`); // Incluye el queryString en la URL
    console.log(resp);
    return resp;
    
  } catch (error) {
    console.error("Error al obtener productos:", error);
    throw error;
  }
};


const postRegisterIngress = async (data) => {
  try {
    const resp = await client.post("productos/registrarIngreso/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};

const ingressService = {
  postCreateIngress,
  getAllIngress,
  getIngressById,
  getAllIngressPending,
  getInventory,
  postRegisterIngress,
};

export default ingressService;