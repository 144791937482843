import client from "../client/client";

const postNuevoUsuario = async (data) => {
  let resp = client.post("users/register/", data);

  return await resp;
};

const postUpdateUsuario = async (data) => {
  let resp = client.put("users/register/", data);
  return await resp;
};
const getAllUsuarios = async () => {
  let resp = client.get("users/register/", {
    sku: "",
  });

  return resp;
};

const deleteUsuario = async (data) => {
  let resp = client.delete("users/register/", { data });
  return await resp;
};

const getDeposits = async () => {
  try {
    const resp = await client.get(`users/depositos/`);
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};

const getProfile = async () => {
  try {
    const resp = await client.get("users/perfil/");
    return resp;
  } catch (error) {
    console.error("Error al obtener perfil:", error);

    return {
      user: {
        id: 1,
        user: "Usuario10",
        activo: true,
        fechaCreacion: "2024-03-04T13:34:48.019821Z",
        rol_id: 3,
        rol: "Super Usuario",
        empresa_id: 1,
        empresa: "Voras",
      },
      depositos: [
        {
          id: 2,
          nombre: "Deposito 2",
          localidad: "Alicia",
          provincia: "Córdoba",
          codigoPostal: "5949",
          calle: "Entre Rios",
          numero: "1050",
          piso: "2",
          departamento: "5",
          telefono: "3533456789",
        },
        {
          id: 1,
          nombre: "Deposito 1",
          localidad: "ALICIA",
          provincia: "Córdoba",
          codigoPostal: "5949",
          calle: "Entre Rios",
          numero: "236",
          piso: "1",
          departamento: "2",
          telefono: "3533353345",
        },
      ],
    };
  }
};

let userService = {
  postNuevoUsuario,
  getAllUsuarios,
  deleteUsuario,
  postUpdateUsuario,
  getDeposits,
  getProfile
};

export default userService;
